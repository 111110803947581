import React from 'react';
import { motion } from 'framer-motion';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInUp } from '../../animations/variants';
import { TechCalculator } from '../../components/Calculators/tech-calculator';
import { ScContainer } from '../../components/container/styled';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { ScCalculatorWrapper } from './styled';

export const TechCalculatorSection = () => {
	const { ref, controls } = useContentAnimation();

	return (
		<motion.section initial="hidden" animate={controls} variants={animationContainer}>
			<ScCalculatorWrapper>
				<ScContainer>
					<MotionDiv variants={fadeInUp}>
						<ScTitle ref={ref} centered>
							{'See the savings'}
						</ScTitle>
					</MotionDiv>
					<TechCalculator/>
				</ScContainer>
			</ScCalculatorWrapper>
		</motion.section>
	);
};
