import React from 'react';
import { ScPage } from '../components/page/styled';
import { MainLayout } from '../layouts/main-layout';
import { Hero } from '../sections/TechScheme/Hero';
import { SchemeInfo } from '../sections/TechScheme/SchemeInfo';
import { Benefits } from '../sections/TechScheme/Benefits';
import { TechCalculatorSection } from '../sections/TechScheme/TechCalculatorSection';
import {SEO} from "../components/Seo/Seo";

const GogetaTechScheme = () => {
	return (
		<MainLayout>
			<ScPage>
				<Hero />
				<SchemeInfo />
				<Benefits />
				<TechCalculatorSection />
			</ScPage>
		</MainLayout>
	);
};

export default GogetaTechScheme;

export const Head = () => (
	<SEO
		title="Gogeta Tech Scheme | Fair & Flexible Savings"
		description="Discover Gogeta's fair Cycle to Work Scheme offering great savings and flexibility for employees, effortless management for employers, and a better deal for retailers. Learn more today!"
	/>
)
