import React from 'react';
import { motion } from 'framer-motion';
import { animationContainer } from '../../animations/variants';
import { ScContainer } from '../../components/container/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { ReactComponent as Bot } from '../../assets/icons/bot-wave.svg';
import { ReactComponent as Top } from '../../assets/icons/top-wave.svg';
import { ScBenefitsContainer, ScBenefitsWrapper } from './styled';
import gogetaLaptop from '../../assets/images/gogeta-laptop.png';
import gogetaHeadphones from '../../assets/images/gogeta-headphones.png';
import { BenefitsCard, IBenefitsCard } from '../../components/BenefitsCard';

const items: IBenefitsCard[] = [
	{
		title: 'Upgrade your life with Gogeta Tech',
		text: 'Need a new laptop? Washing machine on the blink? Whatever you need, we\'ve got your back. With Gogeta Tech, you can save up 8% on the latest gear and you can spread the cost over 12 months.',
		image: gogetaLaptop,
		link: '/employee-tech/',
		buttonText: 'Take me to the employee page',
	},
	{
		title: 'An extra slice for employees, at no cost to you',
		text: "Gogeta Tech let's employee pay for the things they need (or just want) out of their gross pay, saving on National Insurance Contributions and spreading the cost over 12 months. It's simple to offer and doesn't cost you a penny.",
		image: gogetaHeadphones,
		link: '/employer-tech/',
		buttonText: 'Take me to the employer page',
	},
];

export const Benefits = () => {
	const { ref, controls } = useContentAnimation();

	return (
		<ScBenefitsContainer>
			<Top className="top" />
			<ScBenefitsWrapper>
				<ScContainer>
					<motion.section
						ref={ref}
						initial="hidden"
						animate={controls}
						variants={animationContainer}
					>
						{items.map(({ title, text, image, link, buttonText }, index) => (
							<BenefitsCard
								key={title}
								title={title}
								text={text}
								image={image}
								link={link}
								buttonText={buttonText}
								inverted={index === 1}
								lastElement={index === items.length - 1}
							/>
						))}
					</motion.section>
				</ScContainer>
			</ScBenefitsWrapper>
			<Bot />
		</ScBenefitsContainer>
	);
};
